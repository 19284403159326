/* eslint-disable camelcase */
/* eslint-disable no-void */
/* eslint-disable no-loop-func */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
// @ts-nocheck
import React, {
  useState, SyntheticEvent, useEffect, useRef,
} from 'react';
import { Tooltip, OverlayTrigger, Button } from 'react-bootstrap';
import {
  useLocation,
  useNavigate,
  useNavigationType,
  UNSAFE_NavigationContext as NavigationContext,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  addActiveNav, addIsDirty, removeActiveNav, shiftTabs,
} from '../../services/navSlice';
import { RootState } from '../../services/store';
import useOutsideClick from '../../hooks/useOutsideClick';
import LeaveModal from '../../components/common/LeaveModal';

const renderTooltip = (str, props) => (
  <Tooltip id="button-tooltip" {...props}>
    {str}
  </Tooltip>
);

function Sidenav(): JSX.Element {
  const { t } = useTranslation('dashboard');
  const user = localStorage.getItem('authData');
  const userData = JSON.parse(user);
  const dispatch = useDispatch();
  const location = useLocation();
  const [modalShow, setModalShow] = useState(false);
  const sideMenuRef = useRef();

  const activeTabss = useSelector(
    (state: RootState) => state.sideNav.activeTabs,
  );
  const navigate = useNavigate();

  const isDirty = useSelector((state) => state.sideNav.isDirty);

  const path = useLocation();

  const [confirms, setConfirms] = useState(false);
  const [navigationPath, setNavigationPath] = useState('');
  const [breadcrumb, setBreadCrumb] = useState('');
  console.log('breadcrumb', breadcrumb);

  const [program, setProgram] = useState(false);
  const [icons, setIcon] = useState('');
  const [singleMenu, setSingleMenu] = useState(false);
  const [mainMenu, setMainMenu] = useState(false);
  const [Hide, setHide] = useState('');
  const [pathss, setPathss] = useState(path.pathname);

  useEffect(() => {
    if (
      !userData.permissions.includes('create:program')
      && !userData.permissions.includes('create:project')
      && !userData.permissions.includes('create:change')
      && !userData.permissions.includes('create:risk')
      && !userData.permissions.includes('create:benefit')
      && !userData.permissions.includes('create:epic')
    ) {
      setSingleMenu(true);
      setHide('transform');
    }
    if (
      !userData.permissions.includes('create:process')
      && !userData.permissions.includes('create:application')
    ) {
      setSingleMenu(true);
      setHide('capab');
    }
    if (
      !userData.permissions.includes('create:program')
      && !userData.permissions.includes('create:process')
      && !userData.permissions.includes('create:application')
      && !userData.permissions.includes('create:project')
      && !userData.permissions.includes('create:change')
      && !userData.permissions.includes('create:risk')
      && !userData.permissions.includes('create:benefit')
      && !userData.permissions.includes('create:epic')
    ) {
      setMainMenu(true);
    }
  }, [userData.permissions]);

  // console.table(path);
  const handleSubMenuClick = (e: SyntheticEvent, val: string) => {
    //  e.stopPropagation();
    //  if (val === 'enter') {
    setProgram(true);
    //  } else {
    //    setProgram(false);
    //  }
  };

  useEffect(() => {
    setPathss(path.pathname);
  }, []);

  useOutsideClick(sideMenuRef, () => {
    setProgram(false);
  });

  const handleSubMenu = (e: SyntheticEvent, to: string, icon: string, brdc:string) => {
    e.preventDefault();
    e.stopPropagation();
    setProgram(false);
    const data = { icon, to };
    if (!isDirty) {
      dispatch({ type: 'RESET_APP' });
      dispatch(addActiveNav(data));
      navigate(`/${to}`, {
        state: {
          to,
          breadcrumb: brdc,
        },
      });
    } else {
      setNavigationPath(`/${to}`);
      setBreadCrumb(brdc);
      setIcon(icon);
      setModalShow(true);
    }
  };

  const hanldeTemptabs = (data) => {
    dispatch(shiftTabs(data));
    if (!isDirty) {
      dispatch({ type: 'RESET_APP' });
      navigate(`/${data.to}`, {
        state: {
          to: data.to,
        },
      });
    } else {
      setNavigationPath(`/${data.to}`);
      setModalShow(true);
    }
  };

  const handleMainNav = (e, to, brdc) => {
    e.preventDefault();
    e.stopPropagation();
    setProgram(false);
    if (!isDirty) {
      if (location?.pathname !== '/dashboard') {
        dispatch({ type: 'RESET_APP' });
      }
      navigate(to, {
        state: {
          breadcrumb: brdc,
        },
      });
    } else {
      setNavigationPath(to);
      setBreadCrumb(brdc);
      setModalShow(true);
    }
  };

  useEffect(() => {
    if (confirms) {
      dispatch({ type: 'RESET_APP' });
      setModalShow(false);
      setConfirms(false);
      dispatch(addIsDirty(false));
      const data = { icon: icons, to: navigationPath.replace('/', '') };
      dispatch(addActiveNav(data));
      navigate(navigationPath, {
        state: {
          breadcrumb,
        },
      });
    }
  }, [confirms]);

  const [tempTabClose, setTempTabClose] = useState({ close: false, to: '', icon: '' });
  const closeRemoveTempTab = ({ to, icon }) => {
    dispatch(removeActiveNav({ to, icon }));
    const res = activeTabss?.filter((at) => at?.icon !== icon);
    if (res && Array.isArray(res) && res?.length) {
      navigate(`/${res[0]?.to}`);
    } else {
      navigate('/dashboard');
    }
  };

  const handleRemoveActiveNav = ({ to, icon }) => {
    if (!isDirty) {
      closeRemoveTempTab({ to, icon });
    } else {
      setTempTabClose({ close: true, to, icon });
      setModalShow(true);
    }
  };

  useEffect(() => {
    if (confirms && tempTabClose?.close) {
      closeRemoveTempTab({ to: tempTabClose?.to, icon: tempTabClose?.icon });
      setTempTabClose({ close: false, to: '', icon: '' });
      setConfirms(false);
    }
  }, [confirms, tempTabClose.close]);

  return (
    <div className="side-nav-wrapper">
      <LeaveModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        func={setConfirms}
      />
      <div className="side-nav primary" ref={sideMenuRef}>
        <ul className="list-unstyled main-menu mb-0">
          <OverlayTrigger placement="right" overlay={renderTooltip('Home')}>
            <li
              className={`text-center ${
                path.pathname === '/dashboard' && 'active'
              }`}
              data-container="body"
              data-toggle="popover"
              data-placement="right"
              data-content="Home"
            >
              {/* <Link
                className="d-block"
                onClick={() => dispatch({ type: 'RESET_APP' })}
                to={path.pathname}
              > */}
              <Button
                variant="link"
                size="lg"
                className="text-decoration-none"
                onClick={(e) => handleMainNav(e, '/dashboard', 'Dashbaord')}
              >
                <span className="icon-home" />
              </Button>
            </li>
          </OverlayTrigger>
          {!mainMenu && (
            <li
              className={`text-center has-sub ${
                program ? 'show-sub active' : ''
              } `}
            >
              <OverlayTrigger
                placement="right"
                overlay={renderTooltip('Create')}
              >
                <Button
                  variant="link"
                  size="lg"
                  data-test-id="create-button"
                  className="text-decoration-none"
                  onClick={(e) => handleSubMenuClick(e, 'enter')}
                >
                  <span className="icon-new" />
                </Button>
              </OverlayTrigger>
              <div className="mega-menu-wrapper">
                <div className={`mega-menu ${singleMenu ? 'single-menu' : ''}`}>
                  <div className="mega-header">
                    {Hide !== 'transform' && (
                      <h5>{t('dashboard:transformation')}</h5>
                    )}
                    {Hide !== 'capab' && <h5>{t('dashboard:capabilities')}</h5>}
                  </div>
                  <div className="mega-body">
                    {Hide !== 'transform' && (
                      <div className="mega-list">
                        <ul className="list-unstyled mb-0">
                          {userData?.permissions.includes('create:program') && (
                            <li>
                              <Button
                                variant="link"
                                data-test-id="create-program"
                                className="text-decoration-none btn btn-link"
                                onClick={(e) => handleSubMenu(e, 'programs', 'icon-program', 'Create Program')}
                              >
                                <span className="icon-program" />
                                {t('dashboard:program')}
                              </Button>
                            </li>
                          )}
                          {userData?.permissions.includes('create:project') && (
                            <li>
                              <Button
                                variant="link"
                                data-test-id="create-project"
                                className="text-decoration-none btn btn-link"
                                onClick={(e) => handleSubMenu(
                                  e,
                                  'projects',
                                  'icon-initiatives',
                                  'Create Project',
                                )}
                              >
                                <span className="icon-initiatives" />
                                {t('dashboard:project')}
                              </Button>
                            </li>
                          )}
                          {userData?.permissions.includes('create:change') && (
                            <li>
                              <Button
                                variant="link"
                                data-test-id="create-change"
                                className="text-decoration-none btn btn-link"
                                onClick={(e) => handleSubMenu(
                                  e,
                                  'change',
                                  'icon-requirement-changes',
                                  'Create Change',
                                )}
                              >
                                <span className="icon-requirement-changes" />
                                {t('dashboard:change')}
                              </Button>
                            </li>
                          )}
                          {userData?.permissions.includes('create:epic') && (
                            <li>
                              <Button
                                variant="link"
                                data-test-id="create-epic"
                                className="text-decoration-none btn btn-link"
                                onClick={(e) => handleSubMenu(e, 'epic', 'icon-epics', 'Create Epic')}
                              >
                                <span className="icon-epics" />
                                {t('dashboard:epic')}
                              </Button>
                            </li>
                          )}

                          {userData?.permissions.includes(
                            'create:maintenance',
                          ) && (
                            <li>
                              <Button
                                variant="link"
                                className="text-decoration-none btn btn-link"
                              >
                                <span className="icon-configuration" />
                                {t('dashboard:maintenance')}
                              </Button>
                            </li>
                          )}

                          {userData?.permissions.includes('create:risk') && (
                            <li>
                              <Button
                                variant="link"
                                data-test-id="create-risk"
                                className="text-decoration-none btn btn-link"
                                onClick={(e) => handleSubMenu(e, 'risk', 'icon-error', 'Create Risk')}
                              >
                                <span className="icon-error" />
                                {t('dashboard:risk')}
                              </Button>
                            </li>
                          )}
                          {userData?.permissions.includes('create:benefit') && (
                            <li>
                              <Button
                                variant="link"
                                data-test-id="create-benefit"
                                className="text-decoration-none btn btn-link"
                                onClick={(e) => handleSubMenu(e, 'benefit', 'icon-benefit', 'Create Benefit')}
                              >
                                <span className="icon-benefit" />
                                {t('dashboard:benefit')}
                              </Button>
                            </li>
                          )}
                        </ul>
                      </div>
                    )}
                    {Hide !== 'capab' && (
                      <div className="mega-list">
                        <ul className="list-unstyled mb-0">
                          {userData?.permissions.includes('create:process') && (
                            <li>
                              <Button
                                variant="link"
                                data-test-id="create-process"
                                className="text-decoration-none btn btn-link"
                                onClick={(e) => handleSubMenu(
                                  e,
                                  'process',
                                  'icon-value-chain',
                                  'Create Process',
                                )}
                              >
                                <span className="icon-value-chain" />
                                {t('dashboard:process')}
                              </Button>
                            </li>
                          )}
                          {userData?.permissions.includes(
                            'create:application',
                          ) && (
                            <li>
                              <Button
                                variant="link"
                                data-test-id="create-application"
                                className="text-decoration-none btn btn-link"
                                onClick={(e) => handleSubMenu(
                                  e,
                                  'application',
                                  'icon-application',
                                  'Create Application',
                                )}
                              >
                                <span className="icon-application" />
                                {t('dashboard:application')}
                              </Button>
                            </li>
                          )}
                          {userData?.permissions.includes(
                            'create:business',
                          ) && (
                          <li>
                            <Button
                              variant="link"
                              data-test-id="create-business"
                              className="text-decoration-none btn btn-link"
                              onClick={(e) => handleSubMenu(
                                e,
                                'business',
                                'icon-business',
                                'Create Business',
                              )}
                            >
                              <span className="icon-business" />
                              Business
                            </Button>
                          </li>
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </li>
          )}
          <li
            className={`text-center ${
              path.pathname === '/collaborate' && 'active'
            }`}
          >
            <OverlayTrigger
              placement="right"
              overlay={renderTooltip('Collaborate')}
            >
              <Button
                variant="link"
                className="text-decoration-none"
                onClick={(e) => handleMainNav(e, '/collaborate', 'Collaborator')}
              >
                <span className="icon-collaborate" />
              </Button>
            </OverlayTrigger>
          </li>
        </ul>
      </div>
      <div className="side-nav secondary">
        <ul className="list-unstyled mb-0">
          {activeTabss
            && activeTabss?.map((acNav: activeTabsa[]) => (
              <li
                key={acNav.to}
                className={`text-center ${
                  `/${acNav.to}` === path.pathname && 'active'
                }`}
              >
                <Button variant="link" className="text-decoration-none close-temp-tab" onClick={() => handleRemoveActiveNav({ to: acNav.to, icon: acNav.icon })}>
                  <span className="icon-close" />
                </Button>
                <OverlayTrigger
                  placement="right"
                  style={{ textTranform: 'capitalize' }}
                  overlay={renderTooltip(
                    acNav.to.charAt(0).toUpperCase() + acNav.to.slice(1),
                  )}
                >
                  <Button
                    variant="link"
                    className="text-decoration-none"
                    onClick={() => hanldeTemptabs({ to: acNav.to, icon: acNav.icon })}
                  >
                    <span className={acNav.icon} />
                  </Button>
                </OverlayTrigger>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}

const Sidebar = React.memo(Sidenav);
export default Sidebar;
