// @ts-nocheck
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { addIsDirty } from '../../services/navSlice';
import LeaveModal from './LeaveModal';

function Breadcrumb({ title }:any):JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isDirty = useSelector((state) => state.sideNav.isDirty);

  const [confirms, setConfirms] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [navigationPath, setNavigationPath] = useState('');
  const [program, setProgram] = useState(false);

  const handleBreadCrums = (e, to) => {
    e.preventDefault();
    e.stopPropagation();
    setProgram(false);
    if (!isDirty) {
      dispatch({ type: 'RESET_APP' });
      navigate(`/${to}`, {
        state: {
          to,
        },
      });
    } else {
      setNavigationPath(`/${to}`);
      setModalShow(true);
    }
  };
  React.useEffect(() => {
    if (confirms) {
      dispatch({ type: 'RESET_APP' });
      setModalShow(false);
      setConfirms(false);
      dispatch(addIsDirty(false));
      navigate(navigationPath);
    }
  }, [confirms]);
  return (
    <div className="breadcrumb-wrapper">
      <LeaveModal show={modalShow} onHide={() => setModalShow(false)} func={setConfirms} />
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          {location?.pathname === '/change-compare'
          && (
          <li>
            <Button className="mr-3" variant="link" type="button" onClick={() => navigate(location?.state?.from, { state: location?.state?.changeState })}>Back</Button>
          </li>
          )}
          <li className="breadcrumb-item"><Button variant="link" type="button" onClick={(e) => handleBreadCrums(e, 'dashboard')}>Home</Button></li>
          {window.location.pathname !== '/dashboard'
            && (
            <li className="breadcrumb-item active" aria-current="page">
              {title}
            </li>
            )}
        </ol>
      </nav>
    </div>
  );
}

export default Breadcrumb;
