/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import user from '../../images/user.png';
import { useUpdateViewMutation } from '../../services/services';

function Notification({ data, setShow, showNotification }) {
  const notificationRef = useRef();
  const navigate = useNavigate();

  const [updateView, updateRes] = useUpdateViewMutation();

  const updateViewNow = (e, dt) => {
    e.stopPropagation();
    updateView({ id: dt.id });
    if (dt.object_type === 'Task') {
      navigate('/collaborate', {
        state: {
          breadcrumb: 'Collaborator',
        },
      });
    }
  };

  useEffect(() => {
    if (updateRes.isSuccess) {
      setShow(false);
    }
  }, [updateRes.isSuccess]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        showNotification
        && notificationRef?.current
        && !notificationRef.current.contains(e.target)
      ) {
        setShow(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showNotification]);

  const navigateToNotifi = () => {
    setShow(false);
    navigate('/all-notifications');
  };

  return (
    <div className="notification-dropdown box-shadow" ref={notificationRef}>
      <div className="notification-inner">
        <div className="notification-header">
          <h3>
            Notifications (
            {data.filter((nt) => nt.viewed === 'Unread').length}
            )
          </h3>
        </div>
        <div className="notification-body">
          {/* {data.some((dt) => (
            dt.viewed === 'Unread')) && <h3>Today</h3>} */}
          <ul className="list-unstyled notification-dropmenu d-flex flex-column">
            {data.map((dt) => (
              // dt.viewed === 'Unread'
              // && (
              <li
                key={dt.id}
                style={dt.viewed === 'Unread' ? { cursor: 'pointer' } : { cursor: 'default' }}
                className={`notification-item d-flex align-items-start ${
                  dt.viewed === 'Unread' ? 'unread' : ''
                }`}
              >
                <span className="notification-category">
                  <span className={dt.object_type === 'Change' ? 'icon-requirement-changes' : dt.object_type === 'Epic' ? 'icon-epics' : 'icon-collaborate'} />
                </span>
                <div
                  className="notification-content"
                  onClick={(e) => (dt.viewed === 'Unread' ? updateViewNow(e, dt) : null)}
                >
                  <span className="notification-title d-block">
                    {`${dt.user_id.first_name} ${dt.user_id.last_name}`}
                  </span>
                  <span className="notification-details d-block">
                    {` ${dt.message}`}
                  </span>
                  {dt.object_type === 'Task' && (
                  <a
                    href="#"
                    className="notification-link btn btn-link d-inline-block"
                    onClick={(e) => updateViewNow(e, dt)}
                  >
                    View
                  </a>
                  )}
                </div>
              </li>
              // )
            ))}
          </ul>

          {/* {data.some((dt) => (
            dt.viewed === 'Unread')) && <h3>Older</h3>}
          <ul className="list-unstyled notification-dropmenu d-flex flex-column">
            {data.map((dt) => (
              dt.viewed === 'Unread'
              && (
              <li
                key={dt.id}
                className={`notification-item d-flex align-items-start ${
                  dt.viewed === 'Unread' ? 'unread' : ''
                }`}
                style={dt.viewed === 'Unread' ? { cursor: 'pointer' } : { cursor: 'default' }}
              >
                <span className="notification-category">
                  <span className={dt.object_type === 'Change' ? 'icon-requirement-changes' : dt.object_type === 'Epic' ? 'icon-epics' : 'icon-collaborate'} />
                </span>
                <div className="notification-content">
                  <span className="notification-title d-block">
                    {`${dt.user_id.first_name} ${dt.user_id.last_name}`}
                  </span>
                  <span className="notification-details d-block">
                    {` ${dt.message}`}
                  </span>
                  {dt.object_type === 'Task' && (
                  <a
                    href="#"
                    className="notification-link btn btn-link d-inline-block"
                    onClick={(e) => updateViewNow(e, dt)}
                  >
                    View
                  </a>
                  )}
                </div>
              </li>
              )
            ))}
          </ul> */}
        </div>
        <div className="notification-footer">
          <Button variant="link" className="btn btn-link" onClick={() => navigateToNotifi()}>View All</Button>
        </div>
      </div>
    </div>
  );
}

export default Notification;
