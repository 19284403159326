import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  jsonData: [],
  sectionData: [],
  benefitValues: {},
  selectedBenefit: [],
  trackingPointData: [],
};

export const benefitSlice = createSlice({
  name: 'risk',
  initialState,
  reducers: {
    addJson: (state, { payload }) => {
      state.jsonData = payload;
    },
    addSection: (state, { payload }) => {
      state.sectionData = payload;
    },
    addTranslation: (state, { payload }) => {
      state.sectionData = payload;
    },
    addBenefitVals: (state, { payload }) => {
      state.benefitValues = payload;
    },
    addTrackingPointData: (state, { payload }) => {
      state.trackingPointData = payload;
    },
    addBenefit: (state, { payload }) => {
      state.selectedBenefit = payload;
    },

  },
});

// Action creators are generated for each case reducer function
export const {
  addJson, addSection, addBenefitVals, addTrackingPointData, addBenefit,
} = benefitSlice.actions;

export default benefitSlice.reducer;
