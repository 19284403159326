/* eslint-disable import/no-named-as-default */
/* eslint-disable import/prefer-default-export */
import React, { lazy } from 'react';
import DashChart from './components/DashChart';
import GanttChart from './components/GanntChart';
import LoginTest from './components/LoginTest';
import AccessDenied from './screens/dashboard/AccessDenied';

const Dashboard = lazy(() => import('./screens/dashboard/Dashboard'));
const Process = lazy(() => import('./screens/process/Process'));
const ProcessTwinEdit = lazy(() => import('./screens/process/ProcessTwin/ProcessTwinEdit'));

const Programs = lazy(() => import('./screens/program/Programs'));
const AuthCallback = lazy(() => import('./components/common/AuthCallBack'));
const Tree = lazy(() => import('./temporary/Tree'));
const PageNotFound = lazy(() => import('./components/PageNotFound'));
const RiskComponent = lazy(() => import('./screens/risk'));
const BenefitComponent = lazy(() => import('./screens/benefits'));
const ReadReviewComponent = lazy(() => import('./components/ReadReview'));
const OnTabReview = lazy(() => import('./components/OnTabReview'));
const Projects = lazy(() => import('./screens/projects/Projects'));
const Change = lazy(() => import('./screens/change/Change'));
const Applications = lazy(() => import('./screens/application/Applications'));
const Business = lazy(() => import('./screens/business/Business'));
const Review = lazy(() => import('./components/common/Review'));
const Kanban = lazy(() => import('./screens/kanban/Kanban'));
const ChangeReadView = lazy(() => import('./screens/ungrouped/ChangeReadView'));
const BODComponent = lazy(() => import('./admin/enums'));
const AppTwin = lazy(() => import('./screens/digitalTwin'));
const AppTwinReadView = lazy(() => import('./screens/ungrouped/AppTwinReadMode'));
const ReadModeChange = lazy(() => import('./screens/change/ReadModeChange'));
const ReadModeProcess = lazy(() => import('./screens/process/ReadModeProcess'));
const Compare = lazy(() => import('./screens/change/readmode/Compare'));
const Epic = lazy(() => import('./screens/epic/Epic'));
const AdminDashoard = lazy(() => import('./screens/admin/AdminDashoard'));
const AddTranslation = lazy(() => import('./components/language/AddTranslation'));
const TransformationSettings = lazy(() => import('./admin/TransformationSettings'));
const CapabilitySettings = lazy(() => import('./admin/CapabilitySettings'));
const GeneralSettings = lazy(() => import('./screens/admin/GeneralSettings'));
const TenantSettings = lazy(() => import('./admin/TenantSettings'));
const AllNotifications = lazy(() => import('./screens/dashboard/AllNotifications'));
const SolSpecs = lazy(() => import('./screens/solution-specification/SolutionSpecification'));
const Delegation = lazy(() => import('./screens/admin/Delegation'));

export const publicRoutes = [
  // { path: '/', Component: LoginTwin },
  { path: '/', Component: LoginTest },
  { path: '*', Component: PageNotFound },
  { path: '/callback', Component: AuthCallback },
  { path: '/unauthorizedAccess', Component: AccessDenied },
];

export const privateRoutes = [
  { path: '/readmode', Component: Review, label: ['readmode'] },
  { path: '/all-notifications', Component: AllNotifications, label: ['readmode'] },
  { path: '/solution-specification', Component: SolSpecs, label: ['readmode'] },
  {
    path: '/dashboard', Component: Dashboard, title: 'Dashboard', label: ['dashboard'],
  },
  {
    path: '/admindashboard', Component: AdminDashoard, title: 'Admin Dashboard', label: ['create:config'],
  },
  {
    path: '/language', Component: AddTranslation, title: 'Manage Language & Translation', label: ['create:config'],
  },
  {
    path: '/programs/', Component: Programs, title: 'Create Program', label: ['create:program', 'update:program', 'view:program'],
  },
  {
    path: '/process', Component: Process, title: 'Create Process', label: ['create:process', 'update:process', 'view:process'],
  },
  {
    path: '/processTwinUpdate', Component: ProcessTwinEdit, title: 'Process', label: ['create:process', 'update:process', 'view:process', 'create:processTwin', 'update:processTwin', 'view:processTwin'],
  },
  {
    path: '/change', Component: Change, title: 'Create Change', label: ['create:change', 'update:change', 'view:change'],
  },
  {
    path: '/projects', Component: Projects, title: 'Create Project', label: ['create:project', 'update:project', 'view:project'],
  },
  {
    path: '/risk', Component: RiskComponent, title: 'Create Risk', label: ['create:risk', 'update:risk', 'view:risk'],
  },
  {
    path: '/benefit', Component: BenefitComponent, title: 'Create Benefit', label: ['create:benefit', 'update:benefit', 'view:benefit'],
  },
  {
    path: '/application', Component: Applications, title: 'Create Application', label: ['create:application', 'view:application', 'update:application'],
  },
  {
    path: '/business', Component: Business, title: 'Create Business', label: ['business', 'create:business', 'view:business', 'update:business'],
  },
  {
    path: '/epic', Component: Epic, title: 'Create Epic', label: ['create:epic', 'update:epic', 'view:epic'],
  },
  {
    path: '/readReview', Component: ReadReviewComponent, title: 'Read Review', label: ['readmode'],
  },
  {
    path: '/review/:slug/:id', Component: OnTabReview, title: 'Read Review', label: ['readmode'],
  },
  { path: '/tree-view', Component: Tree },
  {
    path: '/collaborate', Component: Kanban, title: 'collaborate', label: ['readmode'],
  },
  {
    path: '/transformation-settings', Component: TransformationSettings, title: 'Transformation Settings', label: ['create:config'],
  },
  {
    path: '/capability-settings', Component: CapabilitySettings, title: 'Capability Settings', label: ['create:config'],
  },
  {
    path: '/general-settings', Component: GeneralSettings, title: 'General Settings', label: ['create:config'],
  },
  {
    path: '/tenant-settings', Component: TenantSettings, title: 'Tenant Settings', label: ['create:config'],
  },

  { path: '/changeReadView', Component: ChangeReadView, label: ['create:change', 'update:change', 'view:change', 'readmode'] },
  { path: '/seed-data-settings', Component: BODComponent, label: ['readmode'] },
  { path: '/appTwin', Component: AppTwin, label: ['create:application', 'update:application', 'view:application', 'create:appTwin', 'update:appTwin', 'view:appTwin'] },
  { path: '/readModeChange', Component: ReadModeChange, label: ['create:change', 'update:change', 'view:change', 'readmode'] },
  { path: '/readModeProcess', Component: ReadModeProcess, label: ['create:process', 'update:process', 'view:process', 'readmode'] },
  { path: '/appTwinReadView', Component: AppTwinReadView, label: ['create:appTwin', 'update:appTwin', 'view:appTwin', 'readmode'] },
  { path: '/change-compare', Component: Compare, label: ['readmode'] },
  { path: '/chart', Component: DashChart, label: ['readmode'] },
  { path: '/gantt', Component: GanttChart, label: ['readmode'] },
  { path: '/delegation', Component: Delegation, label: ['readmode'] },

];
