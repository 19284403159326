import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  businessValues: {},
  capabilitiesData: [],
};

export const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    addBusinessVals: (state, { payload }) => {
      state.businessValues = payload;
    },
    addBusinessCaps: (state, { payload }) => {
      const uniqueAppCaps = (payload && payload.length > 0 && Array.isArray(payload))
        ? payload.reduce((unique, o) => {
          if (!unique.some((obj) => obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []) : [];
      state.capabilitiesData = uniqueAppCaps;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addBusinessVals, addBusinessCaps,
} = businessSlice.actions;

export default businessSlice.reducer;
