/* eslint-disable no-extend-native */
/* eslint-disable react/jsx-filename-extension */
// @ts-nocheck
import React, { Profiler } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './scss/style.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
// import { PersistGate } from 'redux-persist/integration/react';
// import { Auth0Provider } from '@auth0/auth0-react';
import { Auth0Provider } from '@auth0/auth0-react';
import { Spinner } from 'react-bootstrap';
import { PersistGate } from 'redux-persist/integration/react';
import LoaderQ from './images/QLorem_Logo_01.svg';
import App from './App';
import store, { persistor } from './services/store';
import { publicRoutes, privateRoutes } from './routes';
import CheckAuth from './screens/dashboard/CheckAuth';

const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:4001' : 'https://sandbox.qlorem.com/api';

const DETECTION_OPTIONS = {

  order: ['localStorage'],

  caches: ['localStorage'],

};
i18next.use(HttpApi).use(initReactI18next).use(LanguageDetector).init({
  detection: DETECTION_OPTIONS,
  keySeparator: '.',
  fallbackLng: 'en',
  returnObjects: true,
  debug: false,
  fallbackNS: false,
  ns: [],
  backend: {
    loadPath: `${baseUrl}/language/getLanguage/{{lng}}-{{ns}}`,
    allowMultiLoading: true,
  },

  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  react: {
    useSuspense: true,
  },

});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Profiler id="app" onRender={(id) => id}>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={`${window.location.origin}/callback`}
      cacheLocation="localstorage"
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <React.Suspense fallback={(
            <div className="loader">
              <img src={LoaderQ} alt="loading" />
            </div>
)}
          >
            <BrowserRouter>
              <Routes>
                {publicRoutes.map(({
                  Component, path,
                }: any) => (
                  <Route
                    path={path}
                    element={(
                      <Component />
                )}
                    key={path}
                  />
                ))}

                {privateRoutes.map(({
                  Component, path, innerComponent, title, label,
                }: any) => (
                  <Route
                    path={path}
                    element={(
                      <App title={title}>
                        <CheckAuth label={label}>
                          <Component />
                        </CheckAuth>
                      </App>
                )}
                    key={path}
                  >
                    {innerComponent?.map(({ SubComponent, subpath }: any) => (
                      <Route
                        path={subpath}
                        element={(<SubComponent />)}
                        key={subpath}
                      />
                    ))}
                  </Route>
                ))}
              </Routes>
            </BrowserRouter>
          </React.Suspense>
        </PersistGate>
      </Provider>
    </Auth0Provider>
  </Profiler>,
);
