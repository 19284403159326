/* eslint-disable import/no-named-as-default */
/* eslint-disable max-len */
// @ts-nocheck
import { configureStore, combineReducers } from '@reduxjs/toolkit';
// import { configureStore, combineReducers } from '';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

import { setupListeners } from '@reduxjs/toolkit/query';
import navSliceReducer from './navSlice';
import formSliceReducer from './formSlice';
import initSliceReducer from './initSlice';
import appSliceReducer from './applicationSlice';
import businessSliceReducer from './businessSlice';
import riskSlice from './riskSlice';
import peopleSliceReducer from './peopleSlice';
import assetsSliceReducer from './assetsSlice';
import valueChainSliceReducer from './valueChainSlice';
import costCenterSliceReducer from './costCenterSlice';
import docInfoSliceReducer from './docInfoSlice';
import reqChangeSliceReducer from './reqChangeSlice';
import editFormSliceReducer from './editFormSlice';
import treeSliceReducer from './treeSlice';
import epicSliceReducer from './epicSlice';
import processSliceReducer from './processSlice';
import redirectStateReducer from './redirectStateSlice';

import {
  createProgramApi, createPeopleApi, createInitiativeApi, FileUploadApi, createRcApi, createLangApi, createApplicationApi, createRiskApi, createBenefitApi, createBusinessUnitApi,
  createDepartmentApi, createOrganizationApi, createTaskApi, createTwinApi, createAppTwinApi, createAuthApi, createDynDtoApi, createEpicApi, createProcessApi, createExtMtDtApi, createCostCenterApi, createTenantApi, createEnumApi,
  createNotificationApi, createStatusApi, createDashboardApi, createCommonApi, createMyPageApi, createBusinessApi,
} from './services';
import benefitSlice from './benefitSlice';

const persistConfig = {
  key: 'qlorem',
  storage,
  blacklist: ['createProgramApi', 'createInitiativeApi', 'createRcApi', 'createApplicationApi', 'createBusinessApi', 'createRiskApi', 'createBenefitApi', 'createEpicApi', 'createProcessApi', 'createMyPageApi'],
};

// const rootReducer = combineReducers({
//   sideNav: navSliceReducer,
//   formJson: formSliceReducer,
//   initData: initSliceReducer,
//   applicationData: appSliceReducer,
//   riskData: riskSlice,
//   benefitData: benefitSlice,
//   peopleData: peopleSliceReducer,
//   assetsData: assetsSliceReducer,
//   valueChainData: valueChainSliceReducer,
//   costCenterData: costCenterSliceReducer,
//   docInfoData: docInfoSliceReducer,
//   rcData: reqChangeSliceReducer,
//   editData: editFormSliceReducer,
//   [createProgramApi.reducerPath]: createProgramApi.reducer,
//   [createInitiativeApi.reducerPath]: createInitiativeApi.reducer,
//   [createRiskApi.reducerPath]: createRiskApi.reducer,
//   [createBenefitApi.reducerPath]: createBenefitApi.reducer,
//   [createApplicationApi.reducerPath]: createApplicationApi.reducer,
//   [createPeopleApi.reducerPath]: createPeopleApi.reducer,
//   [FileUploadApi.reducerPath]: FileUploadApi.reducer,
//   [createRcApi.reducerPath]: createRcApi.reducer,
//   [createLangApi.reducerPath]: createLangApi.reducer,
//   [createBusinessUnitApi.reducerPath]: createBusinessUnitApi.reducer,
//   [createOrganizationApi.reducerPath]: createOrganizationApi.reducer,
//   [createDepartmentApi.reducerPath]: createDepartmentApi.reducer,

// });

const appReducer = combineReducers({
  sideNav: navSliceReducer,
  formJson: formSliceReducer,
  initData: initSliceReducer,
  applicationData: appSliceReducer,
  businessData: businessSliceReducer,
  riskData: riskSlice,
  benefitData: benefitSlice,
  peopleData: peopleSliceReducer,
  assetsData: assetsSliceReducer,
  valueChainData: valueChainSliceReducer,
  costCenterData: costCenterSliceReducer,
  docInfoData: docInfoSliceReducer,
  rcData: reqChangeSliceReducer,
  editData: editFormSliceReducer,
  treeData: treeSliceReducer,
  epicData: epicSliceReducer,
  processData: processSliceReducer,
  redirectData: redirectStateReducer,
  [createProgramApi.reducerPath]: createProgramApi.reducer,
  [createInitiativeApi.reducerPath]: createInitiativeApi.reducer,
  [createRiskApi.reducerPath]: createRiskApi.reducer,
  [createBenefitApi.reducerPath]: createBenefitApi.reducer,
  [createApplicationApi.reducerPath]: createApplicationApi.reducer,
  [createBusinessApi.reducerPath]: createBusinessApi.reducer,
  [createPeopleApi.reducerPath]: createPeopleApi.reducer,
  [FileUploadApi.reducerPath]: FileUploadApi.reducer,
  [createRcApi.reducerPath]: createRcApi.reducer,
  [createLangApi.reducerPath]: createLangApi.reducer,
  [createBusinessUnitApi.reducerPath]: createBusinessUnitApi.reducer,
  [createOrganizationApi.reducerPath]: createOrganizationApi.reducer,
  [createDepartmentApi.reducerPath]: createDepartmentApi.reducer,
  [createTaskApi.reducerPath]: createTaskApi.reducer,
  [createTwinApi.reducerPath]: createTwinApi.reducer,
  [createAppTwinApi.reducerPath]: createAppTwinApi.reducer,
  [createAuthApi.reducerPath]: createAuthApi.reducer,
  [createDynDtoApi.reducerPath]: createDynDtoApi.reducer,
  [createEpicApi.reducerPath]: createEpicApi.reducer,
  [createProcessApi.reducerPath]: createProcessApi.reducer,
  [createExtMtDtApi.reducerPath]: createExtMtDtApi.reducer,
  [createCostCenterApi.reducerPath]: createCostCenterApi.reducer,
  [createTenantApi.reducerPath]: createTenantApi.reducer,
  [createEnumApi.reducerPath]: createEnumApi.reducer,
  [createNotificationApi.reducerPath]: createNotificationApi.reducer,
  [createStatusApi.reducerPath]: createStatusApi.reducer,
  [createDashboardApi.reducerPath]: createDashboardApi.reducer,
  [createCommonApi.reducerPath]: createCommonApi.reducer,
  [createMyPageApi.reducerPath]: createMyPageApi.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    const {
      sideNav,
    } = state;
    state = {
      sideNav,
    };
  }
  if (action.type === 'PROJECT_BY_RELATED_OBJECT') {
    const {
      formJson,
    } = state;
    const { relatedProgData } = formJson;
    state = {
      relatedProgData,
    };
  }
  if (action.type === 'CHANGE_BY_RELATED_OBJECT') {
    const {
      formJson,
    } = state;
    const { relatedProgData, relatedInitData } = formJson;
    state = {
      relatedProgData,
      relatedInitData,
    };
  }
  if (action.type === 'EPIC_BY_RELATED_OBJECT') {
    const {
      formJson,
    } = state;
    const { relatedChangeData } = formJson;
    state = {
      relatedChangeData,
    };
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// );
// const appReducer = (state, action) => {

//   return rootReducer(state, action);
// };

// when a logout action is dispatched it will reset redux state
// if (action.type === 'USER_LOGGED_OUT') {
// we keep a reference of the keys we want to maintain
// other keys will be passed as undefined and this will call
// reducers with an initial state
// const { users, comment } = state;

// state = { users, comment };
// }

const store = configureStore({
  reducer: persistedReducer,
  middleware:
  (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
    .concat([createProgramApi.middleware,
      createInitiativeApi.middleware, createPeopleApi.middleware, FileUploadApi.middleware,
      createRcApi.middleware, createLangApi.middleware, createBusinessUnitApi.middleware, createDepartmentApi.middleware,
      createOrganizationApi.middleware, createRiskApi.middleware, createBenefitApi.middleware, createApplicationApi.middleware, createBusinessApi.middleware,
      createTaskApi.middleware, createTwinApi.middleware, createAppTwinApi.middleware, createAuthApi.middleware, createDynDtoApi.middleware,
      createEpicApi.middleware, createProcessApi.middleware, createExtMtDtApi.middleware, createCostCenterApi.middleware, createTenantApi.middleware, createEnumApi.middleware,
      createNotificationApi.middleware, createStatusApi.middleware, createDashboardApi.middleware, createCommonApi.middleware, createMyPageApi.middleware,
    ]),
});
setupListeners(store.dispatch);
export const persistor = persistStore(store);
export default store;
export type RootState = ReturnType<typeof store.getState>
